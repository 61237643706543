import React from "react"
import Layout from "../../../components/common/Layout"
import Cursor from "../../../components/common/Cursor"
import Footer from "../../../components/common/Footer"
import Kv from "../../../components/common/Kv"
import BtnMain from "../../../components/common/BtnMain"
import Opportunities from "../../../components/section/careers/farm/Opportunities"
import Inside from "../../../components/section/careers/farm/Inside"
import Saas from "../../../components/section/careers/farm/Saas"
import Care from "../../../components/section/careers/farm/Care"

class Job {
  constructor(name, url) {
    this.name = name
    this.url = url
  }
}

const jobList = [
  new Job("労務・総務（グループマネージャークラス）", "https://herp.careers/v1/crisp/bF9gk__OE2xC"),
]

export default function Farm() {
  let title = "CAREERS FARM"
  let description = "テクノロジーを駆使し、新しいレストラン体験を創造する。"
  let page = "careers__farm"
  let under = "p-under"
  let headParent = "CAREERS"
  let headTtl = "THE FARM"
  let blockTtl =
    'テクノロジーを駆使し、<br class="u-none__sp" />新しいレストラン体験を創造する。'
  let blockTxt =
    "CRISPでは、テクノロジーを最大限に活用した外食の進化に本気で取り組んでいます。そのため「THE FARM」のパートナーに求められるのは、自分の専門性を活かして外食のDXを前に推し進めていくという強いチャレンジ精神です。店舗運営だけではなく、アプリやSaaSの開発、デザイン、顧客情報のデータ化やそれに基づくマーケティングまで、多種多様なプロフェッショナルが活躍できる場が揃っています。"
  let dark = "js-scroll__dark"
  let kvSrc = "/asset/img/careers/farm_kv.jpg"
  let kvSrc2x = "/asset/img/careers/farm_kv@2x.jpg"
  let kvSrcWebp = "/asset/img/careers/farm_kv.jpg.webp"
  let kvSrcWebp2x = "/asset/img/careers/farm_kv@2x.jpg.webp"
  let kvAlt = "web開発の様子"

  return (
    <Layout title={title} description={description}>
      <div id="page">
        <div id="careers" className="js-page careers__under">
          <main id="main">
            <div id="contents" className="contents">
              <Cursor/>
              <Kv
                headParent={headParent}
                headTtl={headTtl}
                blockTtl={blockTtl}
                blockTxt={blockTxt}
                page={page}
                under={under}
                dark={dark}
                kvSrc={kvSrc}
                kvSrc2x={kvSrc2x}
                kvSrcWebp={kvSrcWebp}
                kvSrcWebp2x={kvSrcWebp2x}
                kvAlt={kvAlt}
              />
              <Opportunities
                jobList={jobList}
              />
              <Inside/>
              <Saas/>
              <Care/>

              <BtnMain/>
            </div>
          </main>
          <Footer/>
        </div>
      </div>
    </Layout>
  )
}
